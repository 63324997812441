import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, graphql } from "gatsby";
import { FirebaseContext } from "../../components/Firebase";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import Seo from "../seo";
import Header from "../organisms/Header";
import Navigator from "../organisms/Navigator";
import Logo from "../atoms/Logo";
import Loader from "../atoms/Loader";
import Copyright from "../atoms/Copyright";
import Footer from "../organisms/Footer";
import Mountains from "../atoms/Mountains";
import ErrorCode from "../molecules/ErrorCode";

const GuideSearch = (props) => {

  const { user } = useContext(FirebaseContext);

  const guide = props.data.guide,
        areas = guide.areas;

  const searchInput = useRef(null),
        areaSelect = useRef(null);

  const [allRoute, setAllRoute] = useState(null),
        [allFilteredRoute, setAllFilteredRoute] = useState(null),
        [searchQuery, setSearchQuery] = useState(''),
        [areaFilter, setAreaFilter] = useState('All');

  useEffect(() => {

    if (guide) {

      let routeArr = [],
          inGuideName = '',
          inAreaName = '',
          inAreaColor = '',
          inCragName = '';

      // each area
      areas.forEach(area => {

        // each crag
        area.crags.forEach(crag => {
          inGuideName = guide.name;
          inAreaName = crag.area.name;
          inAreaColor = crag.area.color;
          inCragName = crag.name;

          // each wall
          crag.walls.forEach(wall => {

            // each route
            wall.routes.forEach(route => {
              route.guideName = inGuideName;
              route.areaName = inAreaName;
              route.cragName = inCragName;
              route.areaColor = inAreaColor;
  
              routeArr.push(route);
            });
          });
        });
      });

      setAllRoute(routeArr);
      setAllFilteredRoute(routeArr);

    }
  }, [guide, areas]);

  // handle search
  const handleSearch = () => {
    setSearchQuery(searchInput.current.value.toLowerCase());
    filterResults(searchInput.current.value.toLowerCase(), areaSelect.current.value);
  }

  // handle filter
  const handleFilter = () => {
    setAreaFilter(areaSelect.current.value);
    filterResults(searchInput.current.value.toLowerCase(), areaSelect.current.value);
  }

  const filterResults = (query, filter) => {
    if (query !== '' && filter !== 'All') {

      const filtered = allRoute.filter(route => {
        if (
          (route.name.toLowerCase().includes(query) ||
          route.guideName.toLowerCase().includes(query) ||
          route.areaName.toLowerCase().includes(query) ||
          route.cragName.toLowerCase().includes(query) ||
          (route.grade !== null && route.grade.includes(query))) &&
          (route.areaName === filter)) {
          return true;
        } else {
          return false;
        }
      });

      setAllFilteredRoute(filtered);

    } else if (query !== '') {

      const filtered = allRoute.filter(route => {
        if (route.name.toLowerCase().includes(query) ||
          route.guideName.toLowerCase().includes(query) ||
          route.areaName.toLowerCase().includes(query) ||
          route.cragName.toLowerCase().includes(query) ||
          (route.grade !== null && route.grade.includes(query))) {
          return true;
        } else {
          return false;
        }
      });

      setAllFilteredRoute(filtered);

    } else if (filter !== 'All') {

      const filtered = allRoute.filter(route => {
        return route.areaName === filter;
      });

      setAllFilteredRoute(filtered);
      
    } else {
      setAllFilteredRoute(allRoute);
    }
  }

  // to dashed and lowercase
  function toDashedLowercase(title) {
    return title.replace(/\s/g , "-").replace("'" , "").replace("ê", "e").toLowerCase();
  }

  return (
    <>
      {!user ? (
        <Loader />
      ) : (
        <>
          <Seo title={guide.name + ' Search'} />
          {guide.name === 'Montagu' && user.montaguAccess ? (
            <>
              <Header color={"var(--black)"}>
                <Navigator guideName={guide.name} title={'Search'} />
              </Header>
              <main className="static">
                <div className="container">

                  <div className="information">
                    <h3>Find routes in {guide.name}</h3>
                    <div className={`search-form` + (searchQuery !== '' ? ` search-form--active` : ``)}>

                      <input onChange={handleSearch} value={searchQuery} type="text" ref={searchInput} placeholder="Search routes by name or grade..." />
                      {allRoute && allFilteredRoute && searchQuery !== '' && (
                        <small><strong>{allFilteredRoute.length}</strong> out of <strong>{allRoute.length}</strong> routes matches your search</small>
                      )}
                      <hr />

                      {areas && (
                        <div className="input-group" style={{marginTop: '0.75rem'}}>
                          <label htmlFor="areaSelect">Filter by Area</label>
                          <div className="input-group__select">
                          <FontAwesomeIcon icon={ faCaretDown } />
                            <select name="areaSelect" value={areaFilter} ref={areaSelect} onChange={handleFilter}>
                              <option value="All">All Areas</option>
                              {areas.map((area, i) => (
                                <option key={i} value={area.name}>{area.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {allFilteredRoute && allFilteredRoute.length > 0 ? (
                    <ul className="route-list">
                      {allFilteredRoute.map((route, i) => {
                        return (
                          <li key={i} role="presentation">
                            <div className="route">
                              <div className="route__name">
                                {route.name ? (
                                  <>
                                    {route.name}
                                  </>
                                ) : '??'}
                              </div>
                              {route.grade ? (
                                <div className="route__grade" style={{ marginLeft: 'auto' }}>{route.grade}</div>
                              ) : null}
                              <div className="route__links">
                                <Link to={'/' + toDashedLowercase(route.guideName) + '/' + toDashedLowercase(route.areaName)} className="btn btn--pill" style={{ backgroundColor: route.areaColor }}>{route.areaName}</Link>
                                <Link to={'/' + toDashedLowercase(route.guideName) + '/' + toDashedLowercase(route.areaName) + '/' + toDashedLowercase(route.cragName)} className="btn btn--pill" style={{ backgroundColor: route.areaColor }}>{route.cragName}</Link>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <h5>No results found!</h5>
                  )}

                  <Copyright />

                </div>
              </main>
              <Footer />
            </>
          ) : (
            <>
              <main className="center-flex">
                <div className="container">
                  <Logo link={false} classes="logo--large" />
                  <ErrorCode number="403" />
                  <Mountains />
                </div>
              </main>
            </>
          )}
        </>
      )}
    </>
  )
}

export const query = graphql`
  query GuideSearchQuery($guideId: String!) {
    guide(id: {eq: $guideId}) {
      name
      areas {
        name
        crags {
          area {
            name
            color
          }
          name
          walls {
            routes {
              name
              grade
              isTrad
            }
          }
        }
      }
    }
  }
`;

export default GuideSearch;
