import * as React from "react";

const Title = ({title, extra}) => (
  <h1>
    {title}
    {extra && (
      <sub>
        {' - ' + extra}
      </sub>
    )}
  </h1>
);

export default Title;