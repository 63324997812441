import React from "react";

const Copyright = () => {

  return (
    <p>
      <small>
        <strong>© Tony Lourens and RockApp {new Date().getFullYear()}. All rights reserved.</strong><br/>
        <span className="extra-small">No part of this publication or application may be reproduced, stored in any retrieval system, or transmitted in any form, or by any means, whether in part or whole, without the express written permission of the author.</span>
      </small>
    </p>
  )
}

export default Copyright;