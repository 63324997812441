import * as React from "react";
import { Link } from "gatsby";
import { sluggify } from "../../helpers/sluggify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';

import Breadcrumb from "../atoms/Breadcrumb";
import Title from "../atoms/Title";

import "./Navigator.scss";

const Navigator = ({guideName, areaName, title, nameExtra}) => {

  const hasGuide = guideName,
        hasArea = areaName;

  return (
    <>
      <div className="navigator-left">
        {hasArea ? (
          <Link key="1" to={`/${sluggify(guideName)}/${sluggify(areaName)}`}>
            <FontAwesomeIcon icon={ faLongArrowAltLeft } size={ 'lg' } />
          </Link>
        ): [
            hasGuide ? (
              <Link key="2" to={`/${sluggify(guideName)}`}>
                <FontAwesomeIcon icon={ faLongArrowAltLeft } size={ 'lg' } />
              </Link>
            ): (
              <Link key="3" to={`/`}>
                <FontAwesomeIcon icon={ faLongArrowAltLeft } size={ 'lg' } />
              </Link>
            )
          ]
        }
      </div>
      <div className="navigator-right">
        <Breadcrumb guideName={guideName} areaName={areaName} />
        <Title title={title} extra={nameExtra}/>
      </div>
    </>
  );
}

export default Navigator;