import * as React from "react";
import { Link } from "gatsby";
import { sluggify } from "../../helpers/sluggify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import "./Breadcrumb.scss";

const Breadcrumb = ({guideName, areaName}) => {

  const hasGuide = guideName,
        hasArea = areaName;

  return (
    <div className="breadcrumb">
      <span>
        <Link to={`/`}>
          <FontAwesomeIcon icon={ faHome } />
        </Link>
      </span>
      {hasGuide ? (
        <span>
          <Link to={`/${sluggify(guideName)}`}>
            <span>{guideName}</span>
          </Link>
        </span>
      ): null}
      {hasArea ? (
        <span>
          <Link to={`/${sluggify(guideName)}/${sluggify(areaName)}`}>
            <span>{areaName}</span>
          </Link>
        </span>
      ) : null}
    </div>
  );
}

export default Breadcrumb;